
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'
import AppInput from '@/components/atoms/AppInput.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import { LoginUser } from '@/data/dms/dmsData'
import { getDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterLoginForm',
  components: {
    AppInput,
    AppButton,
    TextAlert,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const form = ref({
      userId: '',
      password: '',
    })
    const userIdErrFlag = ref<boolean>(false)
    const passwordErrFlag = ref<boolean>(false)
    const loginErrMsg = ref('')

    const setLoginUser = async (loginUser: LoginUser) => {
      await store.dispatch('setLoginUser', loginUser)
    }
    const removeBannerMessage = async (id: string) => {
      await store.dispatch('deleteBannerMessage', id)
    }
    setTimeout(() => {
      removeBannerMessage('001')
    }, 5000)

    return {
      form,
      userIdErrFlag,
      passwordErrFlag,
      loginErrMsg,

      async login () {
        if (!form.value.userId || !form.value.password) {
          return
        }
        const url = `${BASE_URL}/outer/login`
        const method = 'POST'
        const headers = {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
        const data = new FormData()
        data.append('userId', form.value.userId)
        data.append('password', form.value.password)
        try {
          await axios(url, {
            method: method,
            headers: headers,
            withCredentials: true,
            data,
          })
          const successUrl = `${BASE_URL}/outer/login/success/${form.value.userId}`
          const response = await getDataCredential(successUrl)
          await setLoginUser({
            id: form.value.userId,
            businessRoleGroupId: response.data.data.businessRoleGroupId,
          })
          await router.push('/dms/outer')
        } catch (error: any) {
          const errStatus = error.response.status
          loginErrMsg.value =
            errStatus === 401 ? 'ユーザ名とパスワードが正しいかご確認ください。'
              : errStatus === 403 ? 'ログインできない業務担当者です。'
                : errStatus === 423 ? 'アカウントがロックされています。システム管理者に問い合わせてください。'
                  : 'ログインに失敗しました。'
        }
      },
      blurCheck (type: string) {
        if (type === 'userId') {
          userIdErrFlag.value = !form.value.userId
        } else if (type === 'password') {
          passwordErrFlag.value = !form.value.password
        }
      },
    }
  },
})
