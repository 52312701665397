
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import OuterLoginForm from '@/components/organisms/dms/OuterLogin/OuterLoginForm.vue'
import TheNotificationArea from '@/components/organisms/TheNotificationArea.vue'

export default defineComponent({
  components: {
    OuterLoginForm,
    TheNotificationArea,
  },
  setup () {
    const store = useStore()
    const bannerMessage = computed(() => store.getters.bannerMessage)
    const removeBannerMessage = async (id: string) => {
      await store.dispatch('deleteBannerMessage', id)
    }
    return {
      bannerMessage,
      removeBannerMessage,
    }
  },
})
